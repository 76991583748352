import {
  AddUser,
  CarDetails,
  CarTransactions,
  CareTransaction,
  Cars,
  ChangePassword,
  Finance,
  FinanceEdit,
  Login,
  Profits,
  Transactions,
  Transfer,
  Users,
} from "./pages";
import { Navbar, SideBar } from "./layout";
import { Route, Routes } from "react-router-dom";
import { Container } from "./Tools/Grid-system";
import { P403, P404, P500 } from "./Tools/Error";
import { RequireAuth } from "./Tools/APIs";
import Logo from "./Images/logo.png";

const App = () => {
  return (
    <>
      <div className="relative flex">
        <SideBar />
        <div className="container mx-auto relative">
          <Navbar />
          <Container>
            <Routes>
              <Route path="login" element={<Login />} />
              <Route element={<RequireAuth />}>
                <Route
                  path="*"
                  element={
                    <div className="grid place-content-center h-[80vh]">
                      <img src={Logo} alt="" className="w-[250px]" />
                    </div>
                  }
                />
                <Route path="*" element={<P404 />} />
                <Route path="403" element={<P403 />} />
                <Route path="500" element={<P500 />} />
                <Route path="users">
                  <Route index element={<Users />} />
                  <Route path=":id" element={<AddUser />} />
                </Route>
                <Route path="cars">
                  <Route index element={<Cars />} />
                  <Route path=":id" element={<CarDetails />} />
                  <Route path="transaction/:id" element={<CareTransaction />} />
                </Route>
                <Route path="profits" element={<Profits />} />
                <Route path="transactions">
                  <Route index element={<Transactions />} />
                  <Route path=":id/:idw" element={<CarTransactions />} />
                  <Route path=":id/transfer" element={<Transfer />} />
                </Route>
                <Route path="finance">
                  <Route index element={<Finance />} />
                  <Route path="edit" element={<FinanceEdit />} />
                </Route>
                <Route path="Change-Password" element={<ChangePassword />} />
              </Route>
            </Routes>
          </Container>
        </div>
      </div>
    </>
  );
};

export default App;
