import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFETCH, usePOST } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import { ButtonRed, Title } from "../../components";
import Loading from "../../Tools/Loading";
import { RiLuggageCartFill } from "react-icons/ri";

const FinanceEdit = () => {
  const { id } = useParams();
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    formData,
    loading,
    error,
  } = usePOST({});
  const { data } = useFETCH(`admin/site-info`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(`admin/site-info`);
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    // id !== "add" &&
      setFormData({
        bank_account_number: dataOld?.bank_account_number,
        transaction_price: dataOld?.transaction_price,
        bank_name: dataOld?.bank_name,
        bonus_value: dataOld?.bonus_value,
        account_owner_name: dataOld?.account_owner_name,
      });
  }, [dataOld]);
  return (
    <div className="">
      <Row>
        <Col md={7} className="mx-auto ">
          <div className="border-2  border-Secondary rounded-2xl p-10 ">
            <Title title="تعديل المعاملة" className="mb-14 mt-5" />
            <input
              type="text"
              name="bank_name"
              value={formData?.bank_name}
              onChange={handleChangeInput}
              placeholder="اسم البنك"
              className="border py-4 rounded-xl mb-4 border-Main"
            />
            <input
              type="text"
              name="bank_account_number"
              value={formData?.bank_account_number}
              onChange={handleChangeInput}
              placeholder="رقم الحساب البنكي"
              className="border py-4 rounded-xl mb-4 border-Main"
            />
            <input
              type="text"
              name="transaction_price"
              value={formData?.transaction_price}
              onChange={handleChangeInput}
              placeholder="مبلغ المعاملة"
              className="border py-4 rounded-xl mb-4 border-Main"
            />
            <input
              type="text"
              name="bonus_value"
              value={formData?.bonus_value}
              onChange={handleChangeInput}
              placeholder="قيمة المكافأة"
              className="border py-4 rounded-xl mb-4 border-Main"
            />
            <input
              type="text"
              name="account_owner_name"
              value={formData?.account_owner_name}
              onChange={handleChangeInput}
              placeholder="اسم صاحب الحساب"
              className="border py-4 rounded-xl mb-4 border-Main"
            />
            {loading ? <Loading /> : ""}
            <div className="text-red-600">{error}</div>
            <ButtonRed
              name="تعديل"
              className="p-3"
              onClick={handleSubmitMain}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FinanceEdit;
