import { useFETCH, useFilter } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import { Search, Table } from "../../components";

function Transactions() {
  const { filter } = useFilter();
  const { isLoading, deleteItem, data } = useFETCH(
    `admin/transactions${
      filter.get("page") || filter.get("search") ? "?" : ""
    }${filter.get("page") ? "page=" + filter.get("page") : ""}${
      filter.get("search") ? "&search=" + filter.get("search") : ""
    }`,
    `admin/transactions`
  );
  return (
    <>
      <Search title="المعاملات" />
      {isLoading ? <Loading /> : ""}
      <Table
        thead={[
          "id",
          "اسم صاحب السيارة",
          "اسم الواجد",
          "واتس أب الواجد",
          "اسم الناشر",
          "واتس أب الناشر",
          "لوحة السيارة",
          "هيكل السيارة",
          "الحالة",
        ]}
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        tData={data?.data.data.data}
        tbody={[
          "id",
          "car_owner_name",
          "finder_name",
          "finder_whatsapp",
          "publisher_name",
          "publisher_whatsapp",
          "plate_number",
          "chassis_number",
          "receipt_status",
        ]}
        funDelete={deleteItem}
        link="transactions"
      />
    </>
  );
}
export default Transactions;
