import { Col, Row } from "../../Tools/Grid-system";
import { ButtonRed, Data } from "../../components";
import { fileUrl, useFETCH } from "../../Tools/APIs";
import { useParams } from "react-router-dom";
import Loading from "../../Tools/Loading";

function CarTransactions() {
  const { id, idw } = useParams();
  const { isLoading, data } = useFETCH(
    `admin/transactions/${id}?published_car_id=${idw}`
  );
  const clickZoomInImage = (event) => {
    event.target.requestFullscreen();
  };
  const dataAll = data?.data.data;
  return (
    <div>
      {isLoading ? <Loading /> : ""}
      <Row className="border-4 border-Main p-3 rounded-2xl items-center">
        <>
          <Col lg={4} xs={6}>
            <Data
              name="اسم صاحب السيارة"
              text={
                dataAll?.car_owner_name ? dataAll?.car_owner_name : "غير موجود"
              }
            />
          </Col>
          <Col lg={4} xs={6}>
            <Data
              name="اسم الواجد"
              text={dataAll?.finder_name ? dataAll?.finder_name : "غير موجود"}
            />
          </Col>
          <Col lg={4} xs={6}>
            <Data
              name="واتس أب الواجد"
              text={
                dataAll?.finder_whatsapp
                  ? dataAll?.finder_whatsapp
                  : "غير موجود"
              }
            />
          </Col>
          <Col lg={4} xs={6}>
            <Data
              name="اسم الناشر"
              text={
                dataAll?.publisher_name ? dataAll?.publisher_name : "غير موجود"
              }
            />
          </Col>
          <Col lg={4} xs={6}>
            <Data
              name="واتس أب الناشر"
              text={
                dataAll?.publisher_whatsapp
                  ? dataAll?.publisher_whatsapp
                  : "غير موجود"
              }
            />
          </Col>
          <Col lg={4} xs={6}>
            <Data
              name="رقم السيارة"
              text={dataAll?.plate_number ? dataAll?.plate_number : "غير موجود"}
            />
          </Col>
          <Col lg={4} xs={6}>
            <Data
              name="هيكل السيارة"
              text={
                dataAll?.chassis_number ? dataAll?.chassis_number : "غير موجود"
              }
            />
          </Col>
          <Col lg={4} xs={6}>
            <Data
              name="الحالة"
              text={
                dataAll?.receipt_status
                  ? dataAll?.receipt_status_text
                  : "غير موجود"
              }
            />
          </Col>
          <Col lg={4} xs={6}>
            <div className={`flex items-center gap-1 font-bold text-Main mt-3`}>
              صورة الناشر :
              {dataAll?.publisher_image === null ? (
                <p className="text-black px-2">لا يوجد صورة</p>
              ) : (
                <img
                  src={fileUrl + dataAll?.publisher_image}
                  alt=""
                  className="w-[200px] h-[200px] rounded-xl cursor-default object-contain"
                  onClick={clickZoomInImage}
                />
              )}
            </div>
          </Col>
          <Col lg={4} xs={6}>
            <div className={`flex items-center gap-1 font-bold text-Main mt-3`}>
              صورة الواجد :
              {dataAll?.finder_image === null ? (
                <p className="text-black px-2">لا يوجد صورة</p>
              ) : (
                <img
                  src={fileUrl + dataAll?.finder_image}
                  alt=""
                  className="w-[200px] h-[200px] rounded-xl cursor-default object-contain"
                  onClick={clickZoomInImage}
                />
              )}
            </div>
          </Col>
          {dataAll?.receipt_status === "0" ? (
            ""
          ) : (
            <Col lg={4} className="mx-auto">
              <ButtonRed
                name="عرض الحوالة"
                className="py-2 px-8 mx-auto w-fit mt-3"
                link={`/transactions/${dataAll?.id}/transfer`}
              />
            </Col>
          )}
        </>
      </Row>
    </div>
  );
}
export default CarTransactions;
