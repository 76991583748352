function Data({ name, text, img, className }) {
  const clickZoomInImage = (event) => {
    event.target.requestFullscreen();
  };
  return (
    <div
      className={`flex items-center gap-1 font-bold text-Main mt-3 ${className}`}
    >
      {name}
      <p className="font-normal text-black break-all">: {text}</p>
      {img && <img src={img} alt="" className="w-[150px] h-[150px] rounded-xl" onClick={clickZoomInImage} />}
    </div>
  );
}
export default Data;
