import { Col, Row } from "../../Tools/Grid-system";
import { Data } from "../../components";
import { fileUrl, useFETCH } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import { useParams } from "react-router-dom";
function CarDetails() {
  const { id } = useParams();
  const { isLoading, data } = useFETCH(`admin/cars/${id}`);
  const clickZoomInImage = (event) => {
    event.target.requestFullscreen();
  };
  return (
    <div>
      <Row className="border-4 border-Main p-3 rounded-2xl">
        {isLoading ? <Loading /> : ""}
        <>
          <Col lg={4} xs={6}>
            <Data name="لوحة السيارة" text={data?.data.data.plate_number} />
          </Col>
          <Col lg={4} xs={6}>
            <Data name="هيكل السيارة" text={data?.data.data.chassis_number} />
          </Col>
          <Col lg={4} xs={6}>
            <Data
              name="اسم صاحب السيارة"
              text={data?.data.data.car_owner_name}
            />
          </Col>
          <Col lg={4} xs={6}>
            <Data name="اسم الناشر" text={data?.data.data.publisher_name} />
          </Col>
          {data?.data.data?.image === null ? (
            <p className="text-black px-2">لا يوجد صورة</p>
          ) : (
            <img
              onClick={clickZoomInImage}
              src={fileUrl + data?.data.data.image}
              alt=""
              className="w-[200px] object-contain h-[150px] mx-auto mt-8 cursor-pointer"
            />
          )}
        </>
      </Row>
    </div>
  );
}
export default CarDetails;
