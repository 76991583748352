import { useParams } from "react-router-dom";
import { useFETCH, useFilter } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import { Table, Title } from "../../components";

function CareTransaction() {
  const { id } = useParams();
  const { filter } = useFilter();
  const { isLoading, deleteItem, data } = useFETCH(
    `admin/cars/${id}/transactions`,
    `admin/transactions`
  );
  return (
    <>
      <Title title="المعاملات" />
      {isLoading ? <Loading /> : ""}
      <Table
        thead={[
          "id",
          "اسم صاحب السيارة",
          "اسم الواجد",
          "اسم الناشر",
          "لوحة السيارة",
          "هيكل السيارة",
          "الحالة",
        ]}
        tData={data?.data.data}
        tbody={[
          "id",
          "car_owner_name",
          "finder_name",
          "publisher_name",
          "plate_number",
          "chassis_number",
          "receipt_status",
        ]}
        funDelete={deleteItem}
        link="transactions"
      />
    </>
  );
}
export default CareTransaction;
