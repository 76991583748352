import { useFETCH, useFilter } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import Pagination from "../../Tools/Pagination";
import { Add, Card, Data, Search } from "../../components";

const Users = () => {
  const { filter } = useFilter();
  const { isLoading, deleteItem, data } = useFETCH(
    `admin/users${filter.get("page") || filter.get("search") ? "?" : ""}${
      filter.get("page") ? "page=" + filter.get("page") : ""
    }${filter.get("search") ? "&search=" + filter.get("search") : ""}`,
    `admin/users`
  );

  return (
    <>
      <Search title="المستخدمين" />
      {isLoading ? <Loading /> : ""}
      <Pagination
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
      >
        <Row>
          {data?.data.data.data.map((e) => (
            <Col xl={4} xs={6}>
              <Card edit={`/users/${e.id}`} deleteClick={() => deleteItem(e)}>
                <Data name="اسم المستخدم" text={e.username} />
                <Data name="رقم الهاتف" text={e.phone_number} />
                <Data name="البريد الالكتروني" text={e.email} />
              </Card>
            </Col>
          ))}
        </Row>
      </Pagination>
      <Add link={`/users/add`} />
    </>
  );
};

export default Users;
