import { useState } from "react";
import { Col, Row } from "../../Tools/Grid-system";
import { Title } from "../../components";
import { usePOST } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";

const ChangePassword = () => {
  const [biShow, setBiShow] = useState(false);
  const { handleSubmit, error, loading, handleChangeInput } = usePOST({});
  const handleSubmitMain = () => {
    handleSubmit("admin/change-password");
  };
  return (
    <div>
      <Title title="تغيير كلمة السر" />
      <Row justify="center">
        <Col md={7}>
          <div className="border-2 border-Main rounded-2xl py-10 md:px-16">
            <input
              type={biShow ? "text" : "password"}
              name="old_password"
              onChange={handleChangeInput}
              className="border border-Main py-4 rounded-2xl my-3"
              placeholder="كلمة السر القديمة"
            />
            <input
              type={biShow ? "text" : "password"}
              name="password"
              onChange={handleChangeInput}
              className="border border-Main py-4 rounded-2xl my-3"
              placeholder="كلمة السر الجديدة"
            />
            <input
              name="password_confirmation"
              onChange={handleChangeInput}
              type={biShow ? "text" : "password"}
              className="border border-Main py-4 rounded-2xl my-3"
              placeholder="أعد كلمة السر "
            />
            <div className="text-end text-l text-Brown flex items-center justify-start space-x-2">
              <input
                type="checkbox"
                className="relative w-5 h-5 mx-2"
                onChange={(e) => setBiShow(e.target.checked)}
              />
              <span>إظهار كلمة السر</span>
            </div>
            {loading ? <Loading /> : ""}
            <div className="text-red-600">{error}</div>
            <div
              onClick={handleSubmitMain}
              className="cursor-pointer w-full border border-Main py-3 mt-10 rounded-2xl text-white font-semibold text-xl  text-center bg-gradient-to-l to-Main from-Main"
            >
              حفظ التغييرات
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ChangePassword;
