import { Card, Data } from "../../components";
import { useFETCH } from "../../Tools/APIs";
import { Col } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";

function Finance() {
  const { isLoading, data } = useFETCH(`admin/site-info`);
  return (
    <>
      {isLoading ? <Loading /> : ""}
      <Col md={6} className="mx-auto">
        <Card edit="/finance/edit" deleted>
          <Data name="اسم البنك" text={data?.data.data.bank_name} />
          <Data
            name="رقم الحساب البنكي"
            text={data?.data.data.bank_account_number}
          />
          <Data name="مبلغ المعاملة" text={data?.data.data.transaction_price} />
          <Data name="قيمة المكافأة" text={data?.data.data.bonus_value} />
          <Data name="اسم صاحب الحساب" text={data?.data.data.account_owner_name} />
        </Card>
      </Col>
    </>
  );
}
export default Finance;
