import { useParams } from "react-router-dom";
import { fileUrl, useFETCH, usePOST } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import { ButtonRed, Data, Input } from "../../components";
import Loading from "../../Tools/Loading";

function Transfer() {
  const { id } = useParams();
  const { handleChangeInput, formData, handleSubmit, error, loading } = usePOST(
    {}
  );
  const handleSubmitMain = () => {
    handleSubmit(`admin/transactions/${id}/receipt`);
  };
  const { isLoading, data } = useFETCH(`admin/transactions/${id}/receipt-info`);
  const dataAll = data?.data.data;
  const clickZoomInImage = (event) => {
    event.target.requestFullscreen();
  };
  return (
    <Col md={8} className="border border-Main p-5 rounded-xl mx-auto">
      {isLoading ? <Loading /> : ""}
      <Row>
        <Col lg={4} xs={6} className="mx-auto">
          {dataAll?.receipt_image === null ? (
            <p className="text-black px-2">لا يوجد صورة</p>
          ) : (
            <img
              src={fileUrl + dataAll?.receipt_image}
              alt=""
              onClick={clickZoomInImage}
              className="w-[250px] h-[200px] object-contain cursor-pointer mx-auto mt-5 rounded-xl"
            />
          )}
        </Col>
        <Row>
          <Col xs={6}>
            <Data name="سعر الحوالة" text={dataAll?.receipt_price} />
          </Col>
          {/* <Col xs={6}>
            <Data name=" اسم المرسل" text={dataAll?.receipt_sender_name} />
          </Col> */}
          {dataAll?.receipt_status === "0" && (
            <>
              <Col xs={6}>
                <Data
                  name="سبب رفض الحوالة"
                  text={dataAll?.receipt_sender_name}
                />
              </Col>
            </>
          )}
        </Row>
      </Row>
      <div className="flex justify-center items-center gap-10 my-5">
        <div className="flex justify-center items-center gap-2">
          <input
            type="radio"
            name="accept"
            value={"ACCEPTED"}
            onChange={handleChangeInput}
            className="w-5 h-5"
          />
          <p className="text-xl font-bold">موافقة</p>
        </div>
        <div className="flex justify-center items-center gap-2">
          <input
            type="radio"
            name="accept"
            value={"REFUSED"}
            onChange={handleChangeInput}
            className="w-5 h-5"
          />
          <p className="text-xl font-bold">رفض</p>
        </div>
      </div>
      {formData?.accept === "ACCEPTED" && (
        <>
          <Input
            name="price"
            value={formData?.price}
            onChange={handleChangeInput}
            title="السعر"
          />
        </>
      )}
      {formData?.accept === "REFUSED" && (
        <>
          <Input
            name="receipt_refuse_reason"
            value={formData?.receipt_refuse_reason}
            onChange={handleChangeInput}
            title="سبب الرفض"
          />
        </>
      )}
      {loading ? <Loading /> : ""}
      <div className="text-red-600">{error}</div>
      <ButtonRed name="حفظ" className="py-3" onClick={handleSubmitMain} />
    </Col>
  );
}
export default Transfer;
