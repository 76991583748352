import { useFETCH, useFilter } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { Data, Search, Table } from "../../components";

function Cars() {
  const { filter } = useFilter();
  const { isLoading, deleteItem, data } = useFETCH(
    `admin/cars${filter.get("page") || filter.get("search") ? "?" : ""}${
      filter.get("page") ? "page=" + filter.get("page") : ""
    }${filter.get("search") ? "&search=" + filter.get("search") : ""}`,
    `admin/cars`
  );
  const { data: datas } = useFETCH(`admin/site-info`);
  return (
    <>
      <Search title="السيارات" />
      <Row>
        <Col md={6}>
          <Data
            name="عدد السيارات التي تم ايجدها"
            text={datas?.data.data.found_cars_count}
          />
        </Col>
        <Col md={6} lg={4} xs={6}>
          <Data
            name="عدد السيارات الضائعة"
            text={datas?.data.data.lost_cars_count}
          />
        </Col>
      </Row>
      {isLoading ? <Loading /> : ""}
      <Table
        thead={[
          "Id",
          "لوحة السيارة",
          "هيكل السيارة",
          "صورة السيارة",
          "اسم صاحب السيارة",
          "اسم الناشر",
        ]}
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        tData={data?.data.data.data}
        tbody={[
          "id",
          "plate_number",
          "chassis_number",
          "image",
          "car_owner_name",
          "publisher_name",
        ]}
        funDelete={deleteItem}
        link="cars"
        show="cars/transaction"
      />
    </>
  );
}
export default Cars;
