import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useFETCH, usePOST } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import { ButtonRed, Input, Title } from "../../components";
import Loading from "../../Tools/Loading";

const AddUser = () => {
  const { id } = useParams();
    const {
      handleChangeInput,
      handleSubmit,
      setFormData,
      formData,
      loading,
      error,
    } = usePOST({});
    const { data } = useFETCH(`admin/users/${id}`);
    const handleSubmitMain = (e) => {
      e.preventDefault();
      handleSubmit(id === "add" ? "admin/users" : `admin/users/${id}`);
    };
  let dataOld = data?.data.data;
    useEffect(() => {
      id !== "add" &&
        setFormData({
          username: dataOld?.username,
          phone_number: dataOld?.phone_number,
          email: dataOld?.email,
          password: dataOld?.password,
        });
    }, [dataOld]);
  return (
    <div className="">
      <Row>
        <Col md={7} className="mx-auto ">
          <div className="border-2  border-Main rounded-2xl p-10 ">
            <Title title="أضف مستخدم" className="mb-14 mt-5" />
            <Input
              name="username"
              value={formData?.username}
              onChange={handleChangeInput}
              title="اسم المستخدم"
            />
            <Input
              name="phone_number"
              value={formData?.phone_number}
              onChange={handleChangeInput}
              title="الرقم"
            />
            <Input
              name="email"
              value={formData?.email}
              onChange={handleChangeInput}
              title="الإيميل"
            />
            <Input
              type="password"
              name="password"
              value={formData?.password}
              onChange={handleChangeInput}
              title="كلمة السر"
            />
          <ButtonRed name="حفظ" onClick={handleSubmitMain} className="py-3" />
          </div>
        </Col>
      </Row>
      {loading ? <Loading /> : ""}
      <div className="text-red-600">{error}</div>
      <div className="fixed bottom-10 left-10 flex flex-col gap-2">
        <ButtonRed className="py-2 px-5" name="إلغاء" link="/users" />
      </div>
    </div>
  );
};

export default AddUser;
