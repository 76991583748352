import { CiSearch } from "react-icons/ci";
import { Col, Row } from "../../Tools/Grid-system";
import Title from "../Title/Title";
import { useFilter } from "../../Tools/APIs";

function Search({ title, onChange, value }) {
    const { filter, setFilter } = useFilter();
  return (
    <Row className="" justify={"between"}>
      <Col md={4}>
        <Title className="mb-5" title={title} />
      </Col>
      <Col md={6} className={"flex justify-end"}>
        <div className="border border-Main flex items-center justify-between space-x-2 p-2 rounded-xl">
          <input
            type="search"
            placeholder="إبحث"
            onChange={(e) =>
              setFilter({
                page: filter.get("page") ? filter.get("page") : 1,
                search: e.target.value,
              })
            }
            value={filter.get("search")}
            className=""
          />
          <span>
            <CiSearch size={20} className="h-full" />
          </span>
        </div>
      </Col>
    </Row>
  );
}
export default Search;
