import Delete from "../Delete/Delete";
import { Link } from "react-router-dom";
import Pagination from "../../Tools/Pagination";
import { AiFillEye } from "react-icons/ai";
import { fileUrl } from "../../Tools/APIs";

const Table = ({ thead, tbody, tData, link, funDelete, pageCount, show }) => {
  return (
    <div>
      <div className="mx-auto w-full py-4 overflow-x-scroll h-full">
        <Pagination pageCount={pageCount}>
          <table className="text-xl text-center w-full mx-auto border overflow-hidden rounded-2xl h-full">
            <thead className="bg-Main text-white w-full pb-52">
              <tr>
                {thead?.map((e, i) => (
                  <td key={i} className="p-5">
                    {e}
                  </td>
                ))}
                <td className="p-5 w-fit">العمليات</td>
              </tr>
            </thead>
            <tbody>
              {tData &&
                tData?.map((e, i) => (
                  <tr key={i}>
                    {tbody?.map((name, i) => (
                      <>
                        <td key={i} className="border-Main border p-2">
                          {name === "image" ? (
                            e[name] === null ? "لا يوجد صورة" :
                            <img
                              src={fileUrl + e[name]}
                              alt=""
                              className="w-12 h-12 rounded-full mx-auto"
                            />
                          ) : name === "receipt_status" ? (
                            e[name] === "0" ? (
                              "لم يقم برفع حوالة بعد "
                            ) : e[name] === "1" ? (
                              "بانتظار موافقة الادمن "
                            ) : e[name] === "2" ? (
                              "تم القبول"
                            ) : (
                              "تم الرفض"
                            )
                          ) : e[name] ? (
                            e[name]
                          ) : (
                            "___"
                          )}
                        </td>
                      </>
                    ))}

                    <td className="cursor-pointer relative border-Main border">
                      <div className="flex justify-center gap-3">
                        {show && (
                          <Link to={`/${show}/${e.id}`}>
                            <div className="bg-Main rounded-xl hover:bg-opacity-70 text-center font-semibold text-white cursor-pointer p-2 mx-2">
                              عرض المعاملات
                            </div>
                          </Link>
                        )}
                        {funDelete && <Delete onClick={() => funDelete(e)} />}
                        {link && (
                          <div
                            className={` w-10 h-10 rounded-full  flex justify-center items-center cursor-pointer hover:bg-slate-200`}
                          >
                            <Link
                              to={`/${link}/${e.id}/${
                                e.published_car_id ? e.published_car_id : ""
                              }`}
                            >
                              <AiFillEye
                                size={30}
                                className="hover:text-green-700 text-slate-700"
                              />
                            </Link>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Pagination>
      </div>
    </div>
  );
};

export default Table;
