import { useFETCH } from "../../Tools/APIs";
import { Col } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { Card } from "../../components";

const Profits = () => {
  const { isLoading, data } = useFETCH(`admin/profits`);
  return (
    <>
      {isLoading ? <Loading /> : ""}
      <Col md={4} className="mx-auto text-center font-semibold">
        <Card deleted showEdit>
          {data?.data.data.total_profits} جنيه  
        </Card>
      </Col>
    </>
  );
};

export default Profits;
