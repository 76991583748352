import { useState } from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
const Delete = ({ onClick }) => {
  const [sure, setSure] = useState(false);
  return (
    <>
      {sure && (
        <>
          <div
            className="fixed  top-0 left-0 w-full h-full bg-black bg-opacity-60 z-50"
            onClick={() => setSure(false)}
          ></div>
          <div
            className={`fixed  top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 popup z-50 flex justify-center items-center`}
          >
            <div className="bg-white z-[60] rounded-3xl w-[500px] max-w-[500px] min-h-[200px]">
              <p className="font-semibold text-3xl text-center py-7">
                هل أنت متأكد من حذف هذا العنصر ؟
              </p>
              <div className="flex items-end m-5">
                <div className="flex gap-9 mx-3">
                  <div
                    className="px-7 py-3 bg-green-600 my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                    onClick={onClick}
                  >
                    نعم
                  </div>
                </div>

                <button
                  onClick={() => setSure(false)}
                  className="px-7 py-3 bg-red-600 my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                >
                  لا
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div>
        <div
          onClick={() => setSure(true)}
          className="w-10 h-10 hover:text-red-700 text-slate-700 rounded-full hover:bg-slate-200 flex cursor-pointer justify-center items-center"
        >
          <RiDeleteBin5Line size={30} className="" />
        </div>
      </div>
    </>
  );
};

export default Delete;
