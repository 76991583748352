import { BiMenu } from "react-icons/bi";
import Logo from "../../Images/logo.png";

import { useState } from "react";
import { Link } from "react-router-dom";
import { useContextHook } from "../../Context/ContextOPen";
import { ButtonRed } from "../../components";

const Navbar = () => {
  const { changeMenu } = useContextHook();
  const [sure, setSure] = useState(false);
  const handleFormSubmit = () => {
    window.location.reload();
  };

  return (
    <>
      <div
        onClick={() => setSure(false)}
        className={`${
          sure ? "" : "hidden"
        } fixed w-full  h-full top-0 left-0 bg-black/30 z-50 flex justify-center items-center`}
      >
        <div className="bg-white z-[60] rounded-3xl w-[500px] max-w-[500px] min-h-[200px]">
          <p className="font-semibold text-3xl text-center py-7">
            هل انت متأكد من تسجيل الخروج ؟
          </p>
          <div className="flex items-end m-5">
            <Link to="/login" onClick={handleFormSubmit}>
              <ButtonRed
                className="py-3 px-10"
                onClick={() => localStorage.clear()}
                name="نعم"
              />
            </Link>
            <button
              onClick={() => setSure(false)}
              className="mx-2 border px-10 border-Secondary text-Secondary bg-white font-semibold  p-3 rounded-xl ml-5"
            >
              إلغاء
            </button>
          </div>
        </div>
      </div>

      <div className="container mx-auto sticky top-0 z-40 bg-white flex items-center justify-between flex-wrap mb-5 pb-2 border-b-2 border-Setext-Secondary">
        <div>
          <BiMenu
            size={35}
            className="text-Secondary cursor-pointer"
            onClick={changeMenu}
          />
        </div>
        <div className="">
          <img src={Logo} alt="" className="w-20 m-1" />
        </div>
        <div className="flex gap-9 mx-3">
          <ButtonRed
            className="px-7 py-3"
            onClick={() => setSure(true)}
            name="تسجيل خروج"
          />
        </div>
      </div>
    </>
  );
};

export default Navbar;
