import { useContextHook } from "../../Context/ContextOPen";
import "./SideBar.css";
import { NavLink } from "react-router-dom";
import logo from "../../Images/logo.png";

function SideBar() {
  const { openMenu, changeMenu } = useContextHook();
  const sidebar = [
    { name: "المستخدمين", link: "/users" },
    { name: "السيارات", link: "/cars" },
    { name: "المعاملات", link: "transactions" },
    { name: "الأرباح", link: "profits" },
    { name: "تعديل أمور مالية", link: "finance" },
    { name: "تغيير كلمة المرور", link: "/Change-Password" },
  ];
  return (
    <div
      className={`${openMenu ? "w-[360px]" : " w-[0px]"} ${
        openMenu
          ? " max-sm:translate-x-0 max-sm:w-[100%] "
          : " max-sm:-translate-x-full"
      } sidebar h-[100vh] bg-Main max-sm:h-[100%] sticky max-sm:fixed left-0 top-0 transition-all overflow-y-scroll  py-10 z-40`}
    >
      <div
        className={`max-sm:block hidden text-end mx-4 text-xl font-semibold cursor-pointer`}
        onClick={changeMenu}
      >
        X
      </div>
      <dFiv className="px-3">
        <ul className="text-white text-center font-semibold space-y-4 max-sm:mt-10">
          <li className="">
            <img src={logo} alt="" className="w-[150px] mx-auto" />
          </li>
          {sidebar.map((e) => (
            <li className=" ">
              <NavLink
                to={e.link}
                className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-Main"
              >
                {e.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </dFiv>
    </div>
  );
}

export default SideBar;
